import React, { Component } from 'react'
import { API_BASE_URL } from '../../../config'
import axios from 'axios';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInputGroup,
  CRow , CSpinner
} from '@coreui/react'
import HtmlParser from 'react-html-parser';

export class Forget extends Component {
    constructor(props) {
        super(props)
        this.state = {
             returnedtime:0,
             isloading:false,
             email:'',
             loginErrors:"",
             errors:'',
             errorsA:'',
             token:'0',
             vcode:'',
             vcode2:'',
             password:'',
             passwordConfirmation:'',
             success:'',
             expired:false,
        }
        //this.returnedtime=0;
    }

    handleChange=(event)=>{
        this.setState({
            [event.target.name] :event.target.value
        })
        //console.log(this.state);
    }
    handleSubmit=(event)=>{
      this.setState({ errors:"" , errorsA:""});

      if(this.state.email.length<2){
        this.setState({ 
          errors:"Veuillez entrer votre adresse e-mail associée !",
          errorsA:" الرجاء إدخال عنوان البريد الإلكتروني المرتبط بحسابك "
      })
      } else {
        this.setState({
          isloading: true
        });
        const formData = new FormData();           
        formData.append("email", this.state.email);

      axios.post(API_BASE_URL+'/members/forget',formData)
      .then(res => {
        console.log('rerrrr',res)
       this.setState({ expired: false });
        if(res.data.status=='ok'){
          //this.returnedtime=res.data.times;
          this.setState({ isloading: false , vcode:res.data.code,token:'1'
           ,returnedtime:res.data.times });
        }else if (res.data.status=='fail'){
          this.setState({ isloading: false ,
        errors:"Cet e-mail n'est pas associé à un compte existant !",
        errorsA: " عنوان الايميل هذا غير مرتبط بأي حساب لدينا"
        });
        }
        
      })
      .catch(error =>{
        console.log("login errors : ", error);

      this.setState({isloading: false , expired: false})
  
    })
   
  }
      event.preventDefault();
     
  }

  handleSubmitNew=(event)=>{
    this.setState({
    vcode2:'',
    vcode:'',
    returnedtime:0 });
this.handleSubmit(event);
  }

    handleSubmit2=(event)=>{
    this.setState({ errors:"",errorsA:""});
   //console.log(this.state);
   let timebetween=(Date.now()/1000)-this.state.returnedtime;
  //  console.log(Date.now()/1000);
  // console.log(this.state.returnedtime , '--- ',(4 * 1000 * 60 * 60));
  //  console.log(timebetween);
    if(this.state.vcode2==this.state.vcode){
      if(timebetween < (4 * 1000 * 60 * 60))
      //if(timebetween < (9000)) 
    {
        this.setState({ token:'2'});
      }else{
        this.setState({ errors:"Ce code a expiré !" ,errorsA : ' رمز التحقق هذا لم يعد صالح ' , expired:true});
          }
      
    } else {   
      this.setState({ errors:"Mauvais code !" , errorsA:"رمز خاطئ !"});
    }
  
    event.preventDefault();  
}
handleSubmit3=(event)=>{
  this.setState({ errors:"",errorsA:""});
  event.preventDefault();
  if(this.state.password.length<8){
    this.setState({ errors:"Le mot de passe doit contenir au moins 8 caractères!",
                    errorsA:" كلمة المرور يجب أن تحتوي 8 محارف على الأقل " });  
  } else if (this.state.passwordConfirmation !== this.state.password) {
    this.setState({ 
    errors:"La confirmation ne correspond pas au mot de passe!",
    errorsA:" تأكيد كلمة المرور غير متطابق مع كلمة المرور " });  
  } else {
    this.setState({isloading: true});
    this.setState({ errors:"" , errorsA:""});  
    const formData = new FormData();
    formData.append("email", this.state.email);
    formData.append("password", this.state.passwordConfirmation);
    formData.append("passwordConfirmation", this.state.passwordConfirmation);
    //console.log('formdata');
    //console.log(formData);
    axios
    .post(API_BASE_URL + "/members/updatepass", formData)
    .then((res) => {
      //console.log(res.data);
      if(res.data.status=='ok'){

          this.setState({
            isloading: false,
            token:'3',
            
          })
   
      }else  
        this.setState({isloading: false,
          errors:"Une erreur est survenue, veuillez réessayer ultérieurement,si cela persiste veuillez contacter le support sur <a href='mailto:support@tunisia-living.com'>support@tunisia-living.com </a>",
          errorsA:" حدث خطأ ما ، الرجاء المحاولة لاحقاً ، اذا استمرت المشكلة لا تتردد بطلب المساعدة من فريق الدعم على العنوان التالي <a href='mailto:support@tunisia-living.com'>support@tunisia-living.com </a> "
        })

      
    })
        
    .catch((error) => {
      //console.log("Register errors : ", error);
      this.setState({isloading: false,
        errors:"Une erreur est survenue, veuillez réessayer ultérieurement,si cela persiste veuillez contacter le support sur <a href='mailto:support@tunisia-living.com'>support@tunisia-living.com </a>",
        errorsA:" حدث خطأ ما ، الرجاء المحاولة لاحقاً ، اذا استمرت المشكلة لا تتردد بطلب المساعدة من فريق الدعم على العنوان التالي <a href='mailto:support@tunisia-living.com'>support@tunisia-living.com </a> "
      })
    });
  
  }

   
}
LoginRedirect=()=>{
  //console.log('loggggggggg');
  window.location.href = '/members/login';
}
  
    render() {
        return (
            <div className="c-app c-default-layout flex-row align-items-center">
           {this.state.token==='0' && <CContainer>
              <CRow className="justify-content-center">
                <CCol md="6">
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                        <form onSubmit={this.handleSubmit}>
                          <h1>Vous avez oublié votre mot de passe ?</h1>
                          <p className="text-muted">Veuillez entrer votre adresse e-mail associée ci-dessous:</p>
                          <CInputGroup className="mb-3">
                          
                            <input type="text" placeholder="Email"  name="email" value={this.state.email} onChange={this.handleChange}/>
                          </CInputGroup>
                          <CRow>
                            <CCol xs="6">
                              <CButton color="primary" className="px-4" type="submit" disabled={this.state.isloading }>
                             
                            Envoyez-moi un code de vérification   {
                              this.state.isloading &&   <CSpinner color="default" size="sm" />
                              }</CButton>
                            </CCol>
                          </CRow>
                        </form>
                        {this.state.errors.length > 1 && <p style={{color:'red'}}>{this.state.errors}</p>}
                        {this.state.errorsA.length > 1 && <p style={{color:'red'}}>{this.state.errorsA}</p>}
                     
                      </CCardBody>
                    </CCard>
                    
                  </CCardGroup>
                </CCol>
              </CRow>
            </CContainer>}
            {this.state.token==='1' && <CContainer>
              <CRow className="justify-content-center">
                <CCol md="6">
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                        <form onSubmit={this.handleSubmit2}>
                          <h1>Vous avez oublié votre mot de passe ?</h1>
                          <p className="text-muted">Nous avons envoyé un code de vérification à votre adresse e-mail. Veuillez l'entrer ci-dessous :</p>
                          <CInputGroup className="mb-3">
                          
                            <input type="text" placeholder="Verification code"  name="vcode2" value={this.state.vcode2} onChange={this.handleChange}/>
                          </CInputGroup>
                          {!this.state.expired && <CRow>
                            <CCol xs="6">
                              <CButton color="primary" className="px-4" type="submit">Valider</CButton>
                            </CCol>
                          </CRow>}
                        </form>
                        {this.state.errors.length > 1 && <p style={{color:'red'}}>{this.state.errors}</p>}
                        {this.state.errorsA.length > 1 && <p style={{color:'red'}}>{this.state.errorsA}</p>}
                        {this.state.expired &&
                        <CRow> <CCol xs="6">
                              <CButton color="primary" disabled={this.state.isloading} className="px-4" onClick={this.handleSubmitNew}>Eenvoyez-moi un nouveau code
                              {
                              this.state.isloading &&   <CSpinner color="default" size="sm" />
                              }</CButton>
                        </CCol>
                        </CRow>}
                       
                      </CCardBody>
                    </CCard>
                    
                  </CCardGroup>
                </CCol>
              </CRow>
            </CContainer>}
            {this.state.token==='2' && <CContainer>
              <CRow className="justify-content-center">
                <CCol md="6">
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                        <form onSubmit={this.handleSubmit3}>
                          <h1>Vous avez oublié votre mot de passe ?</h1>
                          <p className="text-muted">Veuillez saisir votre nouveau mot de passe:</p>
                          <CInputGroup className="mb-3" style={{display:"block"}}>
                          <div className="mb-3">
                        <label htmlFor="password">Mot de passe*</label>
                        <input
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                        />
                      </div>
                        </CInputGroup>
                         <CInputGroup className="mb-3" style={{display:"block"}}>
                      <div className="mb-3">
                        <label htmlFor="passwordConfirmation">
                          Confirmer le mot de passe *
                        </label>
                        <input
                          type="password"
                          name="passwordConfirmation"
                          value={this.state.passwordConfirmation}
                          onChange={this.handleChange}
                        />
                      </div>
                        </CInputGroup>
                          <CRow>
                            <CCol xs="6">
                              <CButton color="primary" className="px-4" disabled={this.state.isloading} type="submit">Soumettre le mot de passe
                              {
                              this.state.isloading &&   <CSpinner color="default" size="sm" />
                              }</CButton>
                            </CCol>
                          </CRow>
                        </form>
                        {this.state.errors.length > 1 && <p style={{color:'red'}}>{HtmlParser(this.state.errors)}</p>}
                        {this.state.errorsA.length > 1 && <p style={{color:'red'}}>{HtmlParser(this.state.errors)}</p>}
                      </CCardBody>
                    </CCard>
                    
                  </CCardGroup>
                </CCol>
              </CRow>
            </CContainer>}
            {this.state.token==='3' && <CContainer>
              <CRow className="justify-content-center">
                <CCol md="6">
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                    
                <div><p className="loadingnow" style={{fontSize:"18px",color:"green",fontWeight:"bold",marginLeft:"4px",marginTop:"10px"}}>
                Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.
                </p>
                        <br/>
                        <CButton style={{display:'block',margin:"auto"}}
                        color="primary" className="px-4" onClick={this.LoginRedirect}>Connexion</CButton>
                        </div>
                        
                      </CCardBody>
                    </CCard>
                    
                  </CCardGroup>
                </CCol>
              </CRow>
            </CContainer>}
                  </div>
        )
    }
}

export default Forget
