import React, { useState, useEffect , useContext } from "react";
import { CButton, CRow } from "@coreui/react";
import { temp_sub_url , API_BASE_URL ,IMAGES_BASE_URL , keyHeader , tokenCookie } from "src/config";
import ItemCard from "../itemCard";
import { CCol , CSpinner } from "@coreui/react";
import axios from "axios";
import { isLogin } from "../../middleware/auth";
import LoginMember from "./LoginMember";
import Loading from "../../myWidgets/loadingWidget";
import FavoriteContext from "../FavoriteContext";
import apiClient from "../apiClient";

function Favorite () {
  const [vehicle, setVehicle] = useState([]);
  const [goodPlans, setGoodPlans] = useState([]);
  const [realEstate, setRealEstate] = useState([]);
  const [animal, setAnimal] = useState([]);
  const [articles, setArticles] = useState([]);
  const [service, setService] = useState([]);
  const [jobOffer, setJobOffer] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [deleting , setdeleting] = useState(false);
  const [prevLength , setprevLength] = useState(0);

  const { favorites , removeFromFavorites} = useContext(
    FavoriteContext
  );

  const removeFavorite = async (id,table) => {
    setdeleting(true);
  
    try {
      const success = await removeFromFavorites(id,table);
  
      if (success) {

        if(table=='Vehicle'){
          setVehicle((prevState) =>
          prevState.filter(
            (obj) => obj.id !== id 
          )
        );
        //console.log(vehicle);
       } 
          else  if(table=='Service'){
            setService((prevState) =>
            prevState.filter(
              (obj) => obj.id !== id 
            )
          );
      }
          else  if(table=='Animal'){
            setAnimal((prevState) =>
            prevState.filter(
              (obj) => obj.id !== id 
            )
          );
      }
          else  if(table=='GoodPlans'){
            setGoodPlans((prevState) =>
            prevState.filter(
              (obj) => obj.id !== id 
            )
          );
        }
          else  if(table=='Articles'){
            setArticles((prevState) =>
            prevState.filter(
              (obj) => obj.id !== id 
            )
          );
              }
          else  if(table=='RealEstate'){
            setRealEstate((prevState) =>
            prevState.filter(
              (obj) => obj.id !== id 
            )
          );
              }  else  if(table=='JobOffer'){
                setJobOffer((prevState) =>
                prevState.filter(
                  (obj) => obj.id != id 
                )
              );
              //console.log(vehicle);
              }

        setdeleting(false);
      } else {
     
        setdeleting(false);
      }
    } catch (error) {
      console.error(error);
      setdeleting(false);
    }
  };


  useEffect(() => {
    console.log('fav list 11gjh');
    if(favorites)
    { 
      setprevLength(favorites.length)
   if(favorites.length>=prevLength)  { //setIsLoaded(false);

    apiClient
    .post("/favorite/By_member")
    .then((result) => {
      console.log('fav list 11');
      console.log(result);
      //console.log(result.data.goodPlans);
      //console.log('fav list 22');
   
      setGoodPlans(result.data.GoodPlans);
      setVehicle(result.data.Vehicle);
      setArticles(result.data.Articles);
      setAnimal(result.data.Animal);
      setRealEstate(result.data.RealEstate);
      setService(result.data.Service);
      setJobOffer(result.data.JobOffer);

      setIsLoaded(true);
    })
    .catch((error) => {
      setIsLoaded(true);
      setError('error')
      //console.log(error);
    });
  }
  }

  }, [favorites]);

   if(!isLogin) {
    return <LoginMember/> ;
  }
 else if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
<Loading/>
    );
  } 
  else if  (
 isLoaded && (goodPlans.length + articles.length 
        + vehicle.length + realEstate.length
        + animal.length + service.length + jobOffer.length == 0 )
)
  {    
    return (
      <div>
             <CRow className="mainBannerText">
             Vous n'avez pas encore d'items favoris !
           </CRow>
          </div>
    )
  }
  else return (
    <div className="container">
       <main className="l-main">
      <div className="l-main__article l-main__content l-main__section l-article ">
           <div className="occasions-results layout-list">
            <div className="articles fav_page_item">

  <div className="row itemsRow ">  
        
  {jobOffer && jobOffer.map((element) => {
                return (
                  element && <CCol md="4" xs="6" key={element.id}>
                     {deleting?
                      <CSpinner className="remove_button remove_buttonSpinner" color="default" size="sm" />
                    :<CButton className="remove_button" onClick={() =>  removeFavorite(element.id,'JobOffer')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" fill="red"></path> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" fill="red"></path> </svg>
                    </CButton>} 
                    <ItemCard
                  item={element}
                        id = {element.id}
                         table='JobOffer'
                          title= {element.title}
                          subtitle={
                          element.createdBy.accountType.id==2 ? element.createdBy.nom : 
                          element.company?element.company.name:
                          element.createdBy.company ?
                          element.createdBy.company.name
                          :'job company'
                        }
                          image={
                              element.createdBy.accountType.id==2 ? `${IMAGES_BASE_URL}/public/uploads/members/${element.createdBy.path}`                  
                                  : element.company? `${IMAGES_BASE_URL}/public/uploads/companies/${element.company.path}`
                                    : element.createdBy.company.path
                                    ? `${IMAGES_BASE_URL}/public/uploads/companies/${element.createdBy.company.path}`
                                    : "https://www.keejob.com/static/recruiter/images/default-logo.jpg"
                                }
                                alt={
                          element.createdBy.accountType.id==2 ?   element.createdBy.nom :     
                          element.company?element.company.name:
                          element.createdBy.company ?
                          element.createdBy.company.name
                          :'job company'
                        }     
                          url={`/jobs/job-details/${element.id}/${element.sector.slug}/${element.slug}`}
                   
                        />

                  </CCol>
                );
              })}

              {goodPlans &&
                goodPlans.map((element) => {
                  return (
                    element &&  <CCol md="4" xs="6" key={element.id}>
                    {deleting?
                      <CSpinner className="remove_button remove_buttonSpinner" color="default" size="sm" />
                    :<CButton className="remove_button" onClick={() =>  removeFavorite(element.id,'GoodPlans')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" fill="red"></path> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" fill="red"></path> </svg>
                    </CButton>} 
                    <ItemCard
                        item={element}
                        id = {element.id}
                         table='GoodPlans'
                          title={
                            element.nom && element.nom.length > 0
                              ? element.nom
                              : element.typeGoodPlans.label
                          }
                          price={element.tickets 
                             ? element.tickets
                             : element.price 
                          }
                          subtitle={element.categoryGoodPlans.label}
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${element.mainPicture.name}`
                              : "assets/icons/loisir.svg"
                          }
                          url={`/bon-plans/details/${element.id}`}
                          at={element.createdAt}
                        />
                    </CCol>
                  );
                })}

                {articles &&
                  articles.map((element) => {
                  return (
                    element &&
                      <CCol md="4" xs="6" key={element.id}>
                    {deleting?
                      <CSpinner className="remove_button remove_buttonSpinner" color="default" size="sm" />
                    :<CButton className="remove_button" onClick={() =>  removeFavorite(element.id,'Articles')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" fill="red"></path> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" fill="red"></path> </svg>
                    </CButton>} 
                      {
                        <ItemCard
                        item={element}
                        id = {element.id}
                         table='Articles'
                          title={
                            element.nom && element.nom.length>0 ? element.nom
                              : element.categoryArticle ? element.categoryArticle.label
                              : ""
                          }
                          price={element.price}
                          subtitle={
                            element.typeArticle
                              ? element.typeArticle.label
                              : ""
                          }
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture && element.typeArticle
                              ? `${IMAGES_BASE_URL}/public/uploads/images/${element.typeArticle.section}/${element.mainPicture.name}`
                              : "assets/icons/icone-electromenager.svg"
                          }
                          url={`/articles/details/${element.id}`}
                          at={element.createdAt}
                        />
                      }
                    </CCol>
                  );
                })}

                {vehicle &&
                  vehicle.map((element) => {
                  return (
                    element && <CCol md="4" xs="6" key={element.id}>
                    {deleting?
                      <CSpinner className="remove_button remove_buttonSpinner" color="default" size="sm" />
                    :<CButton className="remove_button" onClick={() =>  removeFavorite(element.id,'Vehicle')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" fill="red"></path> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" fill="red"></path> </svg>
                    </CButton>} 
                    <ItemCard
                        item={element}
                        id = {element.id}
                         table='Vehicle'
                          title={element.brand &&  element.model && element.model.label ? 
                            element.brand.label +' '+ element.model.label 
                            : element.brand &&  element.model ? 
                            element.brand.label +' '+ element.model
                            :element.brand &&  element.category ? 
                            element.brand.label +' '+ element.category.label 
                            :''
                          }
                          price={element.price}
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/${element.type}/${element.mainPicture.name}`
                              : `assets/icons/${element.type}.svg`
                          }
                          url={`/vehicles/${element.type}/details/${element.id}`}
                          at={element.createdAt}
                        />

                    </CCol>
                  );
                })}

                {realEstate &&
                  realEstate.map((element) => {
                  return (
                    element && <CCol md="4" xs="6" key={element.id}>
                    {deleting?
                      <CSpinner className="remove_button remove_buttonSpinner" color="default" size="sm" />
                    :<CButton className="remove_button" onClick={() =>  removeFavorite(element.id,'RealEstate')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" fill="red"></path> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" fill="red"></path> </svg>
                    </CButton>} 
                    <ItemCard
                    item={element}
                       id = {element.id}
                         table='RealEstate'
                          title={element.title}
                          price={element.price}
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/${element.type}/${element.mainPicture.name}`
                              : `assets/icons/${element.type}.svg`
                          }
                          url={`/real-estates/details/${element.id}`}
                          at={element.createdAt}
                        />
                    </CCol>
                  );
                })}
                
                {animal &&
                  animal.map((element) => {
                  return (
                    element && <CCol md="4" xs="6" key={element.id}>
                    {deleting?
                      <CSpinner className="remove_button remove_buttonSpinner" color="default" size="sm" />
                    :<CButton className="remove_button" onClick={() =>  removeFavorite(element.id,'Animal')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" fill="red"></path> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" fill="red"></path> </svg>
                    </CButton>} 
                    <ItemCard
                        item={element}
                        id = {element.id}
                         table='Animal'
                          title={element.title}
                          subtitle={element.typeAnimal && element.typeAnimal.label}
                          city= {element.city && element.city.label}
                          price={element.price ? element.price : ""}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/animal/${element.mainPicture.name}`
                              : "assets/icons/chien-blanc.svg"
                          }
                          url={`/animals/details/${element.id}`}
                          at={element.createdAt}
                        />
                    </CCol>
                  );
                })}

                {service &&
                  service.map((element) => {
                  return (
                    element &&  <CCol md="4" xs="6" key={element.id}>
                    {deleting?
                      <CSpinner className="remove_button remove_buttonSpinner" color="default" size="sm" />
                    :<CButton className="remove_button" onClick={() =>  removeFavorite(element.id,'Service')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" fill="red"></path> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" fill="red"></path> </svg>
                    </CButton>} 
                      {
                       <ItemCard
                        item={element}
                        id = {element.id}
                         table='Service'
                          title={element.title}
                          price={element.price}
                          subtitle={element.typeService.label}
                          city= {element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/service/${element.mainPicture.name}`
                              : "assets/icons/house.svg"
                          }
                          url={`/services/details/${element.id}`}
                          at={element.createdAt}
                        />
                      }
                    </CCol>
                  );
                })}

            </div>

            </div>
           </div>
        </div>
      </main>
     </div>
    )
//  if  (
//   favList.goodPlans.length + favList.articles.length 
//        + favList.vehicles.length + favList.realEstate.length
//        + favList.animals.length + favList.services.length
//        + favList.jobs.length == 0
// ) {return 
//     (
//     <div>
//       <row className="mainBannerText">
//         Vous n'avez pas encore d'articles favoris !
//       </row>
//     </div>
//   );} else return (
//     <div className="container">
//       <main className="l-main">
//         <div className="l-main__article l-main__content l-main__section l-article ">
//           <div className="occasions-results layout-list">
//             <div className="articles fav_page_item">
//               {favorites.favorites &&
//                 favorites.favorites.toReversed().map((car, index) => {
//                   return (
//                     <div className="favItemwithRemove">
//                       <VehicleItem
//                         key={index}
//                         id={car.id}
//                         make={
//                           car.make
//                             ? car.make
//                             : car.title
//                             ? car.title
//                             : car.type
//                             ? car.type
//                             : ""
//                         }
//                         model={car.model && car.model}
//                         price={car.price && car.price}
//                         image={car.picture ? `${car.picture}` : ""}
//                         url={car.url ? car.url : ""}
//                         mileage={car.mileage}
//                         category={
//                           car.typeRealEstate
//                             ? car.typeRealEstate
//                             : car.category && car.category
//                         }
//                         surface={car.surface && car.surface}
//                         city={car.city}
//                         boite={car.boite}
//                         fuel={car.fuel}
//                         year={car.year}
//                       />
//                       <CButton
//                         onClick={() => {
//                           removeFavorite(car.id, car.itemType);
//                         }}
//                         className="removefromfav"
//                       >
//                         <svg
//                           width="20px"
//                           xmlns="http://www.w3.org/2000/svg"
//                           viewBox="0 0 512 512"
//                         >
//                           <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
//                         </svg>
//                       </CButton>
//                     </div>
//                   );
//                 })}
//             </div>
//           </div>
//         </div>
//       </main>
//     </div>
//   );
// };
  }
export default Favorite;
