import axios from "axios";
import { API_BASE_URL , tokenCookie , keyHeader } from "src/config";

// LOGOUT
export const logoutOld = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("username");
  localStorage.removeItem("email");
  localStorage.removeItem("token");
  localStorage.removeItem("roles");
  localStorage.removeItem("id");
  localStorage.removeItem("avatar");
  localStorage.removeItem("name");
  localStorage.removeItem("prenom");
  localStorage.removeItem("prevUrl");
  localStorage.removeItem("typeCompte");
  document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

  const formData = new FormData();
  formData.append("id", tokenCookie);

axios.post(`${API_BASE_URL}/members/logout`, formData, keyHeader)
    .then(response => {
      //console.log('response --- ', response);  
    })
    .catch(error => {
        //console.log('error --- ', error)

    });
  // message.success('Logout Success')
};

export const logout = async () => {
  console.log('logginggggggggg outt')
    try {
        await axios.post(`${API_BASE_URL}/logout`, {}, { withCredentials: true });
        localStorage.removeItem("auth");
        localStorage.removeItem("username");
        localStorage.removeItem("email");
        localStorage.removeItem("roles");
        localStorage.removeItem("avatar");
        localStorage.removeItem("name");
        localStorage.removeItem("prenom");
        localStorage.removeItem("prevUrl");
        localStorage.removeItem("typeCompte");
        console.log('logging outt end')
        window.location.replace("/");
       // if (window.location.pathname !== "/") { window.location.replace("/");}
        //window.location.href = '/';
        //props.history.push("/my-profile");
        //setState(false)
    } catch (error) {
        console.error('Logout failed:', error);
    }
};

export const logoutUser = async () => {
  console.log('logginggggggggg user outt')
    try {
        await axios.post(`${API_BASE_URL}/logout`, {}, { withCredentials: true });
        localStorage.removeItem("auth");
        localStorage.removeItem("username");
          localStorage.removeItem("roles");
         localStorage.removeItem("prevUrl");
        console.log('logging outt end')
        window.location.replace("/");
    } catch (error) {
        console.error('Logout failed:', error);
    }
 };

export const logoutLocal = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("username");
  localStorage.removeItem("email");
 // localStorage.removeItem("token");
  localStorage.removeItem("roles");
 // localStorage.removeItem("id");
  localStorage.removeItem("avatar");
  localStorage.removeItem("name");
  localStorage.removeItem("prenom");
  localStorage.removeItem("prevUrl");
  localStorage.removeItem("typeCompte");
 // document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

};

export const logoutUserOld = () => {
 localStorage.removeItem("auth");
localStorage.removeItem("username");
  localStorage.removeItem("roles");
 localStorage.removeItem("prevUrl");

  const formData = new FormData();
  formData.append("id", getCookie('tokenU'));
  //console.log('getCookie ', getCookie('tokenU'));  

 axios.post(`${API_BASE_URL}/users/logout`, formData, keyHeader)
    .then(response => {
      console.log('response --- ', response); 
  document.cookie = `tokenU=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

    })
    .catch(error => {
        //console.log('error --- ', error)

    });
  // message.success('Logout Success')
};

function getCookie(cookieName) {
  const cookieString = document.cookie;
  const cookies = cookieString.split('; ');

  for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === cookieName) {
          return decodeURIComponent(value);
      }
  }

  return null;
}

function isCookieExpired(cookieName) {
  const expirationDateString = getCookie(cookieName);

  if (expirationDateString) {
      const expirationDate = new Date(expirationDateString);

      // Compare the expiration date with the current date
      return expirationDate.getTime() < Date.now();
  }

  // If the cookie doesn't exist, consider it expired
  return true;
}

export const isLoginU = () => {
  //const token = getCookie('tokenU');
  //console.log('token in islogin : ',token);
  //console.log('token expiry in islogin : ',isCookieExpired('token'));
  if (localStorage.getItem("auth"))//&&(token !== null && token !== '' && !isCookieExpired('tokenU'))) 
  return true;
  return false;
};

// LOGIN STATUS
export const isLogin = () => {
  //const token = getCookie('token');
  //console.log('token in islogin : ',token);
  //console.log('token expiry in islogin : ',isCookieExpired('token'));
  if (localStorage.getItem("auth"))//&&(token !== null && token !== '' && !isCookieExpired('token'))) 
    return true;
  return false;
};

export const isGranted = (roles) => {
  // console.log('RRRROOOLLLEs : '+roles )
  var rolesUser = localStorage.getItem("roles");
  for (var i = 0; i < roles.length; i++) {
    if (rolesUser === roles[i]) {
      return true;
    }
  }
  // if(localStorage.getItem('roles')== roles) return true;
  return false;
};
